import styled from 'styled-components';
import RichText
  from '@shipae/components-sandbox/component/rich-text';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { FluidObject } from 'gatsby-image';
import { media } from 'src/views/theme';
import StatisticsTheme
  from '@shipae/components-sandbox/component/statistics/theme';

export const Section = styled.section`
  padding: 9rem calc((100% - var(--container-width)) / 2);
`;

type BgImageProps = {
  fluid: FluidObject | FluidObject[];
};

const MEMBER_WIDTH = 26;
export const ImageEx = styled(GatsbyImage)<BgImageProps>`
  width: ${ MEMBER_WIDTH }rem;
  height: ${ MEMBER_WIDTH }rem;
  border-radius: 1rem;

  ${ media.md } {
    height: ${ MEMBER_WIDTH }rem;
    width: 100%;
  }
`;

export const RichTextEx = styled(RichText)`
  margin-top: 2.5rem;
  text-align: center;
`;

export const TeamMember = styled.div`
  width: ${ MEMBER_WIDTH }rem;
  margin-bottom: 3rem;
  margin-right: 3rem;
`;

export const TeamMembers = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 4rem 0 0 0;
`;

export const StatisticsDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatisticsThemeEx = {
  ...StatisticsTheme,
  Main: styled(StatisticsTheme.Main)`
    margin-top: 1.5rem;
    padding-left: 1rem;
  `,
  Description: styled(StatisticsTheme.Description)`
    padding: 0;

    & a {
      color: black;
    }
  `,
};
