import React from 'react';
import {
  TeamMemberType,
  OurTeamType,
} from 'src/infrastructure/gatsby/types/our-team';
import Statistics from '@shipae/components-sandbox/component/statistics';
import ReactMarkdown from 'markdown-to-jsx';
import SmartLink from 'components/smart-link';
import { useTranslation } from 'src/views/misc/localization';
import {
  Section, ImageEx, RichTextEx, TeamMember, TeamMembers,
  StatisticsDescription, StatisticsThemeEx,
} from './styles';
import gloabalReactMdOptions from '../shared/react-makdown-options';

const OurTeam: React.FC<OurTeamType> = ({
  sectionId,
  description,
  teamMembers,
}) => {
  const { t } = useTranslation();

  return (
    <Section
      id={sectionId}
      data-test="section-our-team"
    >
      <RichTextEx>

        <ReactMarkdown options={gloabalReactMdOptions}>
          {description || ''}
        </ReactMarkdown>
      </RichTextEx>
      <TeamMembers>
        {teamMembers?.map((member: TeamMemberType) => (
          <TeamMember key={member.id}>
            { member?.image?.localFile?.childImageSharp?.fluid
              && (
              <ImageEx
                fluid={member?.image?.localFile?.childImageSharp?.fluid}
                alt={member?.image?.alternativeText || ''}
              />
            )}
            <Statistics
              title={member.position}
              description={(
                <StatisticsDescription>
                  {member.name}
                  <SmartLink href={member.url || '/'}>
                    {t('ourTeam.knowMore')}
                  </SmartLink>
                </StatisticsDescription>
              )}
              color={member?.color || 'black'}
              theme={StatisticsThemeEx}
            />
          </TeamMember>
        ))}
      </TeamMembers>
    </Section>
  );
};

export default OurTeam;
